<script>
import SummaryRowString from "@/components/SummaryRowString.vue";
import router from "@/router";
import axios from 'axios';

export default {
  name: 'SummaryPage',
  components: {SummaryRowString},
  data() {
    return {
      tournament_name : JSON.parse(localStorage.getItem('tournament_name')),
      initiator: JSON.parse(localStorage.getItem('initiator')),
      date: JSON.parse(localStorage.getItem('date')),
      open_for: JSON.parse(localStorage.getItem('open_for')),
      referee_name: JSON.parse(localStorage.getItem('referee_name')),
      referee_club: JSON.parse(localStorage.getItem('referee_club')),
      referee_qualification: JSON.parse(localStorage.getItem('referee_qualification')),
      isOnline: true,
      recipient: '',
    }
  },
  created() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  methods: {
    updateOnlineStatus() {
      this.isOnline = navigator.onLine;
    },
    sendReport() {
      if (this.recipient !== '') {
        const formData = new FormData();
        formData.append('sender', 'osr-bericht-pwa');
        formData.append('version', '0.0.16');
        formData.append('tournament_name', this.tournament_name);
        formData.append('initiator', this.initiator);
        formData.append('date', this.date);
        formData.append('open_for', this.open_for);
        formData.append('referee_name', this.referee_name);
        formData.append('referee_club', this.referee_club);
        formData.append('referee_qualification', this.referee_qualification);
        formData.append('recipient', this.recipient)

        axios.post('https://osr.mawega.com/osr-printer.php', formData)
            .then(response => {
              // Erfolgreiche Antwort verarbeiten
              console.log(response.data);
            })
            .catch(error => {
              // Fehler verarbeiten
              console.error('Fehler beim Senden des Requests:', error);
            });
        localStorage.clear();
        router.push({ path: '/' });
      }
    },
  },
}
</script>

<template>
  <div>
    <h1 class="no-form">Zusammenfassung</h1>
    <div class="summary-block">
      <SummaryRowString summary_row_label="Turnierbezeichnung" :summary_row_value="tournament_name" />
      <SummaryRowString summary_row_label="Durchführer" :summary_row_value="initiator" />
      <SummaryRowString summary_row_label="Einsatztag" :summary_row_value="date" />
      <SummaryRowString summary_row_label="Offen für" :summary_row_value="open_for" />
    </div>
    <div class="summary-block">
      <SummaryRowString summary_row_label="Name des OSR" :summary_row_value="referee_name" />
      <SummaryRowString summary_row_label="Verein des OSR" :summary_row_value="referee_club" />
      <SummaryRowString summary_row_label="Qualifikation des OSR" :summary_row_value="referee_qualification" />
    </div>
    <footer style="justify-content: flex-start; margin-bottom: 25px;">
      <RouterLink to="/schiedsrichter" class="navigation-link"><font-awesome-icon icon="arrow-left" />&nbsp;Schiedsrichter</RouterLink>
    </footer>
    <div v-if="isOnline">
      <div class="form-content">
        <input v-model="recipient" type="text" class="form-element form-input" placeholder=" " required />
        <label for="tournament_name" class="form-label">E-Mail-Adresse des Empfängers</label>
      </div>
      <button @click="sendReport" class="navigation-button  main-button">Abschicken</button>
    </div>
    <div v-else>
      Du bist aktuell offline. Stelle eine Verbindung zum Internet her, um den OSR-Bericht abschicken zu können.
    </div>
  </div>
</template>

<style scoped>
.summary-block {
  &:not(:last-of-type) {
    border-bottom: 1px solid darkgrey;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
}
</style>