<script>
export default {
  props: {
    summary_row_label: String,
    summary_row_value: String,
  }
}
</script>

<template>
  <div class="summary-row">
      <div class="summary-row-title">
        {{ summary_row_label }}:
      </div>
      <div class="summary-row-value">
        {{ summary_row_value }}
      </div>
  </div>
</template>

<style scoped>
.summary-row {
  text-align: left;
  margin-bottom: 10px;
}

.summary-row-title {
  font-weight: bold;
}
</style>