import { createMemoryHistory, createRouter } from 'vue-router'

import HomeView from './components/Home.vue'
import PersonalSettings from './components/PersonalSettings.vue'
import TournamentInfo from './components/TournamentInfo.vue'
import RefereeInfo from './components/RefereeInfo.vue'
import TravelExpenses from './components/TravelExpenses.vue'
import SummaryPage from "@/components/SummaryPage.vue";

const routes = [
    { path: '/', component: HomeView },
    { path: '/einstellungen', component: PersonalSettings },
    { path: '/turnier-informationen', component: TournamentInfo },
    { path: '/schiedsrichter', component: RefereeInfo },
    { path: '/reisekosten', component: TravelExpenses },
    { path: '/zusammenfassung', component: SummaryPage },
]

const router = createRouter({
    history: createMemoryHistory(),
    routes,
})

export default router
