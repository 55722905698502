<template>
  <header>
    <img src="./assets/img/httv-osr-logo.png" alt="HTTV Logo">
  </header>
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
</template>

<script>

export default {
  name: 'OSR-Bericht',
  created () {
    document.title = "OSR-Bericht für Turniere im HTTV - Proof of Concept";
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

h1 {
  margin-top: 0;
  margin-bottom: 50px;
  font-size: 20px;
}

h1.no-form {
  margin-bottom: 25px;
}

header img {
  max-width: 100%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.navigation-button {
  display: inline-block;
  text-decoration: none;
  color: white;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.navigation-button.main-button {
  background: linear-gradient(180deg, #026ab0, #014471);
}

.navigation-button.main-button:hover {
  background: linear-gradient(0deg, #026ab0, #0390ef);
}

.navigation-button.cancel-button {
  background: linear-gradient(180deg, #e2001b, #a20013);
}

.navigation-button.cancel-button:hover {
  background: linear-gradient(0deg, #e2001b, #ff233d);
}

.navigation-link {
  color: #014471;
  text-decoration: none;
  border: none;
  transition: all 0.3s ease-in-out;
}

.navigation-link:hover {
  color: #026ab0;
  border-bottom: 2px solid #026ab0;
}

.form-content {
  position: relative;
  &:not(:last-of-type) {
    margin-bottom: 50px;
  }
}

.form-element {
  font-size: 18px;
  color: #333;
  border-width: 0 0 2px 0;
  border-color: #b3e0fe;
  background-color: #f2faff;
  width: 100%;
  padding: 10px 5px;
  box-sizing: border-box;
}

.form-element:focus {
  background-color: #e5f5ff;
  border-bottom-width: 4px;
}

.form-element:required {
  border-color: #35acfd;
}

textarea.form-input,
.form-input[type="url"],
.form-input[type="text"],
.form-input[type="number"],
.form-input[type="email"] {
  transition: all .3s;

  &:not(:placeholder-shown) ~ .form-label,
  &:-webkit-autofill ~ .form-label {
    top: -22px;
    left: 0;
    color: black;
    font-style: normal;
  }
}

.form-input[type="date"] {
  font-family: Arial, sans-serif;
  color: #333;
}

.form-input[type="hidden"] ~ .form-label,
.form-input[type="date"] ~ .form-label,
.form-input[type="time"] ~ .form-label,
.form-input[type="datetime-local"] ~ .form-label {
  top: -22px;
  left: 0;
  color: black;
  font-style: normal;
}

.form-select {
  appearance: none;
}

.form-select:-webkit-autofill ~ .form-label {
  top: -22px;
  left: 0;
  color: black;
  font-style: normal;
}

.form-select::-ms-expand {
  display: none;
}

.form-select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
  background-position: right 1.5rem top 50%;
  background-repeat: no-repeat;
}

.form-select:not([value=""]):valid ~ .form-label {
  top: -22px;
  left: 0;
  color: black;
  font-style: normal;
}

.form-select[value=""]:focus ~ .form-label {
  top: -22px;
  left: 0;
  color: black;
  font-style: normal;
}

.form-input,
.form-select {
  display: block;
  box-sizing: border-box;

  &:focus {
    outline: none;

    ~ .form-label {
      top: -22px;
      left: 0;
      color: black;
      font-style: normal;
    }
  }

  &:required:focus {
    border-color: #35acfd;
  }
}

.form-label {
  position: absolute;
  color: #999;
  font-size: 18px;
  font-style: italic;
  font-weight: normal;
  pointer-events: none;
  left: 10px;
  top: 10px;
  transition:0.2s ease all;
}

footer {
  display: flex;
  justify-content: space-between;
  margin-block-start: 20px;
  padding-inline: 10px;
  gap: 20px;
}
</style>
