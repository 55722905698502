<script>
import router from '../router'
export default {
  name: 'TournamentInfo',
  data() {
    return {
      tournament_name: JSON.parse(localStorage.getItem('tournament_name')),
      initiator: JSON.parse(localStorage.getItem('initiator')),
      date: JSON.parse(localStorage.getItem('date')),
      open_for: JSON.parse(localStorage.getItem('open_for'))
    }
  },
  methods: {
    saveData(event) {
      localStorage.setItem(event.target.name, JSON.stringify(event.target.value));
    },
    abortEntry() {
      localStorage.clear();
      router.push({ path: '/' })
    }
  },
}
</script>

<template>
  <div>
    <h1>Angaben zum Turnier</h1>
    <div class="form-content">
      <input v-model="tournament_name" type="text" id="tournament_name" name="tournament_name" @blur="saveData" class="form-element form-input" placeholder=" " required />
      <label for="tournament_name" class="form-label">Turnierbezeichnung</label>
    </div>
    <div class="form-content">
      <input v-model="initiator" type="text" id="initiator" name="initiator" @blur="saveData" class="form-element form-input" placeholder=" " required />
      <label for="initiator" class="form-label">Durchführer</label>
    </div>
    <div class="form-content">
      <input v-model="date" type="date" id="date" name="date" @blur="saveData" class="form-element form-input" placeholder=" " required />
      <label for="date" class="form-label">Einsatztag</label>
    </div>
    <div class="form-content">
      <select v-model="open_for" name="open_for" id="open_for" class="form-element form-select" @change="saveData" onchange="this.setAttribute('value', this.value);" value="{{ open_for }}" required>
        <option value="Kreis">Kreis</option>
        <option value="Bezirk">Bezirk</option>
        <option value="Verband">Verband</option>
        <option value="DTTB">DTTB</option>
        <option value="International">International</option>
      </select>
      <label for="open_for" class="form-label">Offen für</label>
    </div>
    <footer style="justify-content: flex-end;">
      <!--button @click="abortEntry" class="navigation-button cancel-button">Eingabe abbrechen</button-->
      <RouterLink to="/schiedsrichter" class="navigation-link">Schiedsrichter&nbsp;<font-awesome-icon icon="arrow-right" /></RouterLink>
    </footer>
  </div>
</template>

<style scoped>

</style>