import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import AddToHomescreen from '@owliehq/vue-addtohomescreen';
import './registerServiceWorker'
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas);

createApp(App)
    .use(AddToHomescreen, {title: 'OSR-Bericht', content: 'Erfahre hier, wie du diese Web-Applikation auf den Homescreen deines Smartphones installieren kannst.', iconPath: 'img/icons/icons8-info-100.png', background: '#b3e0fe', buttonColor: '#0069b4',lang: 'de_DE',})
    .use(router)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount('#app')
