<script>
export default {
  name: 'PersonalSettings',
}
</script>

<template>
  <div>
    <h1>Persönliche Einstellungen</h1>
    <fieldset>

    </fieldset>
    <RouterLink to="/" class="button">zurück</RouterLink>
  </div>
</template>

<style scoped>
  .button {
    color: #333;
    text-decoration: none;
    border: 1px solid darkgray;
    border-radius: 3px;
    background: lightgray;
    padding: 5px;
    transition: all .25s ease-in-out;
  }
  .button:hover {
    color: black;
    border-color: goldenrod;
    background-color: gold;
  }
</style>