<script>
export default {
  name: 'RefereeInfo',
  data() {
    return {
      referee_name: JSON.parse(localStorage.getItem('referee_name')),
      referee_club: JSON.parse(localStorage.getItem('referee_club')),
      referee_qualification: JSON.parse(localStorage.getItem('referee_qualification')),
    }
  },
  methods: {
    saveData(event) {
      localStorage.setItem(event.target.name, JSON.stringify(event.target.value));
    },
  },
}
</script>

<template>
  <div>
    <h1>Angaben zum OSR</h1>
    <div class="form-content">
      <input v-model="referee_name" type="text" id="referee_name" name="referee_name" @blur="saveData" class="form-element form-input" placeholder=" " required />
      <label for="referee_name" class="form-label">Name des OSR</label>
    </div>
    <div class="form-content">
      <input v-model="referee_club" type="text" id="referee_club" name="referee_club" @blur="saveData" class="form-element form-input" placeholder=" " required />
      <label for="referee_club" class="form-label">Verein des OSR</label>
    </div>
    <div class="form-content">
      <select v-model="referee_qualification" name="referee_qualification" id="referee_qualification" class="form-element form-select" @change="saveData" onchange="this.setAttribute('value', this.value);" value="{{ referee_qualification }}" required>
        <option value="KSR">KSR</option>
        <option value="VSR">VSR</option>
        <option value="NSR">NSR</option>
        <option value="NOSR">NOSR</option>
        <option value="IU-WB">IU-WB</option>
        <option value="IU-BB">IU-BB</option>
        <option value="IU-GB">IU-GB</option>
        <option value="IR">IR</option>
      </select>
      <label for="referee_qualification" class="form-label">Qualifikation des OSR:</label>
    </div>
    <footer>
      <RouterLink to="/turnier-informationen" class="navigation-link"><font-awesome-icon icon="arrow-left" />&nbsp;Turnierinfos</RouterLink>
      <RouterLink to="/zusammenfassung" class="navigation-link">Zusammenfassung&nbsp;<font-awesome-icon icon="arrow-right" /></RouterLink>
    </footer>
  </div>
</template>

<style scoped>

</style>