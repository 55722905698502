<template>
  <div>
    <div>
      <RouterLink class="button-big" to="/turnier-informationen">Neuer OSR-Bericht</RouterLink>
      <!--    <RouterLink to="/einstellungen">Persönliche Einstellungen</RouterLink>-->
    </div>
    <p style="margin-top: 25px; font-size: 8px;">v0.0.16</p>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .button-big {
    display: block;
    text-decoration: none;
    color: white;
    font-weight: bold;
    padding: 20px 10px;
    background: linear-gradient(180deg, #026ab0, #014471);
    border-radius: 5px;
    cursor: pointer;
  }
  .button-big:hover {
    background: linear-gradient(0deg, #026ab0, #0390ef);
  }
</style>
